export const CLIENT_REDUX_CONSTANTS = {
  FETCH_CLIENT_LIST_REQUEST: 'FETCH_CLIENT_LIST_REQUEST',
  FETCH_CLIENT_LIST_SUCCESS: 'FETCH_CLIENT_LIST_SUCCESS',
  FETCH_CLIENT_LIST_FAILURE: 'FETCH_CLIENT_LIST_FAILURE',
  CLIENT_LIST_USER_ACTION: 'CLIENT_LIST_USER_ACTION',
  SELECTED_CLIENT_DATA: 'SELECTED_CLIENT_DATA',
  RESET_CLIENT_LIST_PAGINATION_DATA: 'RESET_CLIENT_LIST_PAGINATION_DATA',
  RESET_CLIENT_LIST_DATA: 'RESET_CLIENT_LIST_DATA',

  VIEW_CLIENT_ACTIVE_TAB_INDEX: 'VIEW_CLIENT_ACTIVE_TAB_INDEX',
  RESET_VIEW_CLIENT_DATA: 'RESET_VIEW_CLIENT_DATA',

  CREDIT_LIMIT: {
    CLIENT_CREDIT_LIMIT_LIST_ACTION: 'CLIENT_CREDIT_LIMIT_LIST_ACTION',
    CLIENT_CREDIT_LIMIT_COLUMN_LIST_ACTION: 'CLIENT_CREDIT_LIMIT_COLUMN_LIST_ACTION',
    CLIENT_CREDIT_LIMIT_DEFAULT_COLUMN_LIST_ACTION:
      'CLIENT_CREDIT_LIMIT_DEFAULT_COLUMN_LIST_ACTION',
    UPDATE_CLIENT_CREDIT_LIMIT_COLUMN_LIST_ACTION: 'UPDATE_CLIENT_CREDIT_LIMIT_COLUMN_LIST_ACTION',
  },

  APPLICATION: {
    CLIENT_APPLICATION_LIST_ACTION: 'CLIENT_APPLICATION_LIST_ACTION',
    CLIENT_APPLICATION_COLUMN_LIST_ACTION: 'CLIENT_APPLICATION_COLUMN_LIST_ACTION',
    CLIENT_APPLICATION_DEFAULT_COLUMN_LIST_ACTION: 'CLIENT_APPLICATION_DEFAULT_COLUMN_LIST_ACTION',
    UPDATE_CLIENT_APPLICATION_COLUMN_LIST_ACTION: 'UPDATE_CLIENT_APPLICATION_COLUMN_LIST_ACTION',
  },

  CONTACT: {
    CLIENT_CONTACT_LIST_USER_ACTION: 'CLIENT_CONTACT_LIST_USER_ACTION',
    CLIENT_CONTACT_COLUMN_LIST_USER_ACTION: 'CLIENT_CONTACT_COLUMN_LIST_USER_ACTION',
    CLIENT_CONTACT_COLUMN_DEFAULT_LIST_USER_ACTION:
      'CLIENT_CONTACT_COLUMN_DEFAULT_LIST_USER_ACTION',
    UPDATE_CLIENT_CONTACT_COLUMN_LIST_ACTION: 'UPDATE_CLIENT_CONTACT_COLUMN_LIST_ACTION',
  },

  POLICIES: {
    CLIENT_POLICIES_LIST_USER_ACTION: 'CLIENT_POLICIES_LIST_USER_ACTION',
    CLIENT_POLICIES_COLUMN_LIST_USER_ACTION: 'CLIENT_POLICIES_COLUMN_LIST_USER_ACTION',
    CLIENT_POLICIES_COLUMN_DEFAULT_LIST_USER_ACTION:
      'CLIENT_POLICIES_COLUMN_DEFAULT_LIST_USER_ACTION',
    UPDATE_CLIENT_POLICIES_COLUMN_LIST_ACTION: 'UPDATE_CLIENT_POLICIES_COLUMN_LIST_ACTION',
  },

  NOTES: {
    CLIENT_NOTES_LIST_USER_ACTION: 'CLIENT_NOTES_LIST_USER_ACTION',
  },
  DOCUMENTS: {
    CLIENT_DOCUMENTS_LIST_USER_ACTION: 'CLIENT_DOCUMENTS_LIST_USER_ACTION',
    CLIENT_DOCUMENTS_MANAGEMENT_COLUMN_LIST_ACTION:
      'CLIENT_DOCUMENTS_MANAGEMENT_COLUMN_LIST_ACTION',
    CLIENT_DOCUMENTS_MANAGEMENT_DEFAULT_COLUMN_LIST_ACTION:
      'CLIENT_DOCUMENTS_MANAGEMENT_DEFAULT_COLUMN_LIST_ACTION',
    UPDATE_CLIENT_DOCUMENTS_COLUMN_LIST_ACTION: 'UPDATE_CLIENT_DOCUMENTS_COLUMN_LIST_ACTION',
    CLIENT_DOCUMENT_TYPE_LIST_USER_ACTION: 'CLIENT_DOCUMENT_TYPE_LIST_USER_ACTION',
    UPLOAD_DOCUMENT_CLIENT_ACTION: 'UPLOAD_DOCUMENT_CLIENT_ACTION',
  },
  TASK: {
    CLIENT_TASK_LIST_ACTION: 'CLIENT_TASK_LIST_ACTION',
    CLIENT_TASK_COLUMN_NAME_LIST_ACTION: 'CLIENT_TASK_COLUMN_NAME_LIST_ACTION',
    CLIENT_TASK_DEFAULT_COLUMN_NAME_LIST_ACTION: 'CLIENT_TASK_DEFAULT_COLUMN_NAME_LIST_ACTION',
    UPDATE_CLIENT_TASK_COLUMN_NAME_LIST_ACTION: 'UPDATE_CLIENT_TASK_COLUMN_NAME_LIST_ACTION',
    ADD_TASK: {
      CLIENT_UPDATE_ADD_TASK_FIELD_ACTION: 'CLIENT_UPDATE_ADD_TASK_FIELD_ACTION',
      CLIENT_ASSIGNEE_DROP_DOWN_DATA_ACTION: 'CLIENT_ASSIGNEE_DROP_DOWN_DATA_ACTION',
      CLIENT_ENTITY_DROP_DOWN_DATA_ACTION: 'CLIENT_ENTITY_DROP_DOWN_DATA_ACTION',
      DEFAULT_CLIENT_ENTITY_DROP_DOWN_DATA_ACTION: 'DEFAULT_CLIENT_ENTITY_DROP_DOWN_DATA_ACTION',
      CLIENT_RESET_ADD_TASK_STATE_ACTION: 'CLIENT_RESET_ADD_TASK_STATE_ACTION',
    },
    EDIT_TASK: {
      GET_CLIENT_TASK_DETAILS_ACTION: 'GET_CLIENT_TASK_DETAILS_ACTION',
    },
  },
  CLIENT_OVERDUE: {
    GET_CLIENT_OVERDUE_LIST: 'GET_CLIENT_OVERDUE_LIST',
    GET_CLIENT_OVERDUE_ENTITY_LIST: 'GET_CLIENT_OVERDUE_ENTITY_LIST',
    RESET_CLIENT_OVERDUE_LIST_DATA: 'RESET_CLIENT_OVERDUE_LIST_DATA',
  },
  CLIENT_CLAIMS: {
    CLIENT_CLAIMS_LIST_SUCCESS: 'CLIENT_CLAIMS_LIST_SUCCESS',
    RESET_CLIENT_CLAIM_LIST_DATA: 'RESET_CLIENT_CLAIM_LIST_DATA',

    GET_CLIENT_CLAIMS_COLUMNS_LIST: 'GET_CLIENT_CLAIMS_COLUMNS_LIST',
    GET_CLIENT_CLAIMS_DEFAULT_COLUMN_LIST: 'GET_CLIENT_CLAIMS_DEFAULT_COLUMN_LIST',
    UPDATE_CLIENT_CLAIMS_COLUMNS_LIST: 'UPDATE_CLIENT_CLAIMS_COLUMNS_LIST',
  },
};
export const CLIENT_MANAGEMENT_COLUMN_LIST_REDUX_CONSTANTS = {
  CLIENT_MANAGEMENT_COLUMN_LIST_ACTION: 'CLIENT_MANAGEMENT_COLUMN_LIST_ACTION',
  CLIENT_MANAGEMENT_DEFAULT_COLUMN_LIST_ACTION: 'CLIENT_MANAGEMENT_DEFAULT_COLUMN_LIST_ACTION',
  UPDATE_CLIENT_MANAGEMENT_COLUMN_LIST_ACTION: 'UPDATE_CLIENT_MANAGEMENT_COLUMN_LIST_ACTION',
};
export const CLIENT_MANAGEMENT_FILTER_LIST_REDUX_CONSTANTS = {
  CLIENT_MANAGEMENT_FILTER_LIST_ACTION: 'CLIENT_MANAGEMENT_FILTER_LIST_ACTION',
};
export const CLIENT_ADD_FROM_CRM_REDUX_CONSTANT = {
  CLIENT_GET_LIST_FROM_CRM_ACTION: 'CLIENT_GET_LIST_FROM_CRM_ACTION',
  CLIENT_SEARCH_LIST_IN_CRM_ACTION: 'CLIENT_SEARCH_LIST_IN_CRM_ACTION',
};

export const MY_WORK_REDUX_CONSTANTS = {
  MY_WORK_TASK_REDUX_CONSTANTS: {
    GET_TASK_LIST_SUCCESS_ACTION: 'GET_TASK_LIST_SUCCESS_ACTION',
    TASK_COLUMN_NAME_LIST_ACTION: 'TASK_COLUMN_NAME_LIST_ACTION',
    TASK_DEFAULT_COLUMN_NAME_LIST_ACTION: 'TASK_DEFAULT_COLUMN_NAME_LIST_ACTION',
    UPDATE_TASK_COLUMN_NAME_LIST_ACTION: 'UPDATE_TASK_COLUMN_NAME_LIST_ACTION',
    ASSIGNEE_DROP_DOWN_DATA_FOR_FILTER: 'ASSIGNEE_DROP_DOWN_DATA_FOR_FILTER',
    RESET_MY_WORK_TASK_PAGINATION_DATA: 'RESET_MY_WORK_TASK_PAGINATION_DATA',
    RESET_MY_WORK_TASK_DATA: 'RESET_MY_WORK_TASK_DATA',

    // ADD new task
    MY_WORK_UPDATE_ADD_TASK_FIELD_ACTION: 'MY_WORK_UPDATE_ADD_TASK_FIELD_ACTION',
    MY_WORK_ASSIGNEE_DROP_DOWN_DATA_ACTION: 'MY_WORK_ASSIGNEE_DROP_DOWN_DATA_ACTION',
    MY_WORK_ENTITY_DROP_DOWN_DATA_ACTION: 'MY_WORK_ENTITY_DROP_DOWN_DATA_ACTION',
    RESET_ADD_TASK_STATE_ACTION: 'RESET_ADD_TASK_STATE_ACTION',
    RESET_MY_WORK_ADD_TASK_ENTITY_ID: 'RESET_MY_WORK_ADD_TASK_ENTITY_ID',

    // EDIT task
    GET_TASK_DETAIL_BY_ID_ACTION: 'GET_TASK_DETAIL_BY_ID_ACTION',
    RESET_MY_WORK_TASK_UPDATE_ENTITY_ID: 'RESET_MY_WORK_TASK_UPDATE_ENTITY_ID',
    UPDATE_EDIT_TASK_FIELD_ACTION: 'UPDATE_EDIT_TASK_FIELD_ACTION',
    RESET_EDIT_TASK_STATE_ACTION: 'RESET_EDIT_TASK_STATE_ACTION',
  },
  MY_WORK_NOTIFICATION_REDUX_CONSTANTS: {
    GET_MY_WORK_NOTIFICATION_LIST_REQUEST_ACTION: 'GET_MY_WORK_NOTIFICATION_LIST_REQUEST_ACTION',
    GET_MY_WORK_NOTIFICATION_LIST_SUCCESS_ACTION: 'GET_MY_WORK_NOTIFICATION_LIST_SUCCESS_ACTION',
    GET_MY_WORK_NOTIFICATION_LIST_FAIL_ACTION: 'GET_MY_WORK_NOTIFICATION_LIST_FAIL_ACTION',
    DELETE_MY_WORK_NOTIFICATION_ACTION: 'DELETE_MY_WORK_NOTIFICATION_ACTION',
    CLEAR_NOTIFICATION_DATA: 'CLEAR_NOTIFICATION_DATA',
    GET_NOTIFICATION_FROM_SOCKET: 'GET_NOTIFICATION_FROM_SOCKET',
  },
};
